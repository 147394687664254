export const setAdmin = (state, { admin }) => {

    if (admin) {
        state.admin = admin
    }
}

export const logout = (state) => {
    state.admin = null
}

export const addMachine = (state, { data }) => {

    state.admin.managers.forEach(m => m.town_halls.forEach(th => {
        if (th.id === data.town_hall) {
            th.machines.push(data)
        }
    }
    ))

}

export const addManager = (state, { data }) => {

    state.admin.managers.push(data)

}

export const addTownHall = (state, { data }) => {

    state.admin.managers.forEach(m => {
        if (m.id == data.manager) {
            m.town_halls.push(data)
        }
    })
}

export const addNeighbor = (state, { data }) => {

    state.admin.managers.forEach(m => m.town_halls.forEach(th => {
        if (th.id === data.town_hall) {
            th.neighbors.push(data)
        }
    }))

}

export const addTechnician = (state, { data }) => {    

    state.admin.managers.forEach(m => m.town_halls.forEach(th => {
        th.machines.forEach(machine => {
            if (machine.id === data.machines[0]) {
                m.technicians.push(data)
            }
        })
    }))

}

export const addFarmer = (state, { data }) => {    
    state.admin.managers.forEach(m => m.town_halls.forEach(th => {
        th.machines.forEach(machine => {
            if (machine.id === data.machines[0]) {
                m.farmers.push(data)
            }
        })
    }))
}

export const deleteMachine = (state, id) => {

    state.admin.managers.forEach(m => {
        m.town_halls.forEach(th => {
            th.machines.forEach(n => {
                if (n.id == id) {
                    th.machines.splice(th.machines.findIndex(item => item.id === id), 1)
                }
            })
        })
    })
}

export const deleteNeighbor = (state, id) => {

    state.admin.managers.forEach(m => {
        m.town_halls.forEach(th => {
            th.neighbors.forEach(n => {
                if (n.user_data.id == id) {
                    th.neighbors.splice(th.neighbors.findIndex(item => item.user === id), 1)
                }
            })
        })
    })
}

export const deleteTownHall = (state, id) => {

    state.admin.managers.forEach(m => {
        m.town_halls.forEach(th => {
            if (th.user_data.id == id) {
                m.town_halls.splice(m.town_halls.findIndex(item => item.user === id), 1)
            }
        })
    })
}

export const deleteTechnician = (state, id) => {

    state.admin.managers.forEach(m => {
        m.technicians.forEach(t => {
            if (t.user_data.id == id) {
                m.technicians.splice(m.technicians.findIndex(item => item.user === id), 1)
            }
        })
    })
}

export const deleteFarmer = (state, id) => {

    state.admin.managers.forEach(m => {
        m.farmers.forEach(t => {
            if (t.user_data.id == id) {
                m.farmers.splice(m.farmers.findIndex(item => item.user === id), 1)
            }
        })
    })
}

export const deleteManager = (state, id) => {

    state.admin.managers.forEach(m => {
        if (m.user_data.id == id) {
            state.admin.managers.splice(state.admin.managers.findIndex(item => item.user === id), 1)
        }
    })
}

export const deleteNewUser = (state, id) => {

    state.admin.new_users.forEach(m => {
        if (m.id == id) {
            state.admin.new_users.splice(state.admin.new_users.findIndex(item => item.id === id), 1)
        }
    })
}

export const deleteTutorial = (state, id) => {

    state.auth.tutorials.forEach(t => {
        if (t.id == id) {
            state.auth.tutorials.splice(state.auth.tutorials.findIndex(item => item.id === id), 1)
        }
    })
}

export const updateManager = (state, { data }) => {

    const ind = state.admin.managers.indexOf(state.admin.managers.find(item => item.id === data.id))
    state.admin.managers[ind] = data

}

export const updateTownHall = (state, { data }) => {

    state.admin.managers.forEach(m => {
        const ind = m.town_halls.indexOf(m.town_halls.find(item => item.id === data.id))
        m.town_halls[ind] = data
    })

}

export const updateNeighbor = (state, { data }) => {

    state.admin.managers.forEach(m => m.town_halls.forEach(th => {
        const ind = th.neighbors.indexOf(th.neighbors.find(item => item.id === data.id))
        
        if(ind !== -1){
            const hist = th.neighbors[ind].historical 
            th.neighbors[ind] = data
            th.neighbors[ind].historical = hist
        }        
    }))   

}

export const updateTechnician = (state, { data }) => {

    state.admin.managers.forEach(m => {
        const ind = m.technicians.indexOf(m.technicians.find(item => item.id === data.id))
        m.technicians[ind] = data
    })

}

export const updateFarmer = (state, { data }) => {

    state.admin.managers.forEach(m => {
        const ind = m.farmers.indexOf(m.farmers.find(item => item.id === data.id))
        m.farmers[ind] = data
    })

}

export const updateMachine = (state, { data }) => {

    state.admin.managers.forEach(m => m.town_halls.forEach(th => {
        const ind = th.machines.indexOf(th.machines.find(item => item.id === data.id))
        th.machines[ind] = data     
    }))   

}

export const addVisit = ( state, { data }) => {
    state.admin.sheets_historical.push(data)
    state.admin.sheets_historical.sort((a, b) => {
        return new Date(b.date) - new Date(a.date);
    });

}

export const setUses = (state, data ) => {
    if (data) {
        state.uses = data
    }
}