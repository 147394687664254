export const vecino = ( state ) => {
    return state.vecino || null
}

export const imgKey = ( state ) => {
    return state.vecino?.photo || ''
}

export const question = ( state ) => {
    return state.question || null
}

