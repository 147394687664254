export const ayuntamiento = ( state ) => {
    return state.ayuntamiento || null
}

export const historicoVecinos = ( state ) => {
    return state.ayuntamiento?.neighbors_historical || []
}

export const idsMaquinas = ( state ) => {
    return state.ayuntamiento?.machines.map(machine => {
        return machine.id
    }) || []
}

export const neighbors = (state) => {    
    return state.ayuntamiento?.neighbors.sort((a, b) => a.user_data.last_name.localeCompare(b.user_data.last_name)) || []
}
