import { computed } from 'vue'
import { useStore } from 'vuex'


const useAuth = () => {

    const store = useStore()

    const loginUser = async (user) => {
        const resp = await store.dispatch('auth/signInUser', user)
        return resp
    }

    const getUser = async () => {
        const resp = await store.dispatch('auth/getUser')
        return resp
    }


    const deleteNotification = async (id) => {
        const { ok } = await checkAuthStatus()
        if (ok) {
            const resp = await store.dispatch('auth/deleteNotification', id)
            return resp
        }
        else {
            return { ok: false }
        }
    }

    const checkNotification = async (id) => {
        const { ok } = await checkAuthStatus()
        if (ok) {
            const resp = await store.dispatch('auth/checkNotification', id)
            return resp
        }
        else {
            return { ok: false }
        }
    }

    const resetPass = async (mail) => {
        const resp = await store.dispatch('auth/resetPass', mail)
        return resp
    }

    const newPass = async (form) => {
        const resp = await store.dispatch('auth/newPass', form)
        return resp
    }

    const updateData = async (form) => {
        const { ok } = await checkAuthStatus()
        if (ok) {
            const resp = await store.dispatch('auth/updateData', form)
            return resp
        }
        else {
            return { ok: false }
        }
    }

    const getTutorials = async () => {

        const { ok } = await checkAuthStatus()

        if (ok) {
            const resp = await store.dispatch('auth/getTutorials')
            return resp
        }
        else {
            return { ok: false }
        }
    }

    const updatePassword = async (form) => {
        const { ok } = await checkAuthStatus()
        if (ok) {
            const resp = await store.dispatch('auth/updatePassword', form)
            return resp
        }
        else {
            return { ok: false }
        }
    }

    const resendCard = async (form) => {
        const { ok } = await checkAuthStatus()
        if (ok) {
            const resp = await store.dispatch('auth/resendCard', form)
            return resp
        }
        else {
            return { ok: false }
        }
    }

    const logout = () => {
        store.commit('admin/logout')
        store.commit('ayuntamiento/logout')
        store.commit('empresa/logout')
        store.commit('gestor/logout')
        store.commit('tecnico/logout')
        store.commit('vecino/logout')
        store.commit('agricultor/logout')
        store.commit('auth/logout')
    }

    
    const checkAuthStatus = async () => {
        const resp = await store.dispatch('auth/checkAuthentication')
        if(!resp.ok) {
            logout()
        }
        return resp
    }

    const getRanking = async ( page, rankingType, townHall=null, username=null ) => {
        const { ok } = await checkAuthStatus()
        if (ok) {
            const resp = await store.dispatch('auth/getRanking', { page, rankingType, townHall, username })
            return resp
        }
        else {
            return { ok: false}
        }
    }

    const getRankingSingleNeighbor = async (neighborId, rankingType, townHall=null) => {
        const { ok } = await checkAuthStatus();
        if (ok) {
          const resp = await store.dispatch('auth/getRankingSingleNeighbor', { neighborId, rankingType, townHall });
          return resp;
        } else {
          return { ok: false };
        }
      };
    
    const getLevelRank = async (neighborId) => {
        const { ok } = await checkAuthStatus();
        if (ok) {
          const resp = await store.dispatch('auth/getLevelRank', { neighborId});
          return resp;
        } else {
          return { ok: false };
        }
      };
    

    const getRankingTh = async ( page, rankingType, townHall=null, city=null ) => {
        const { ok } = await checkAuthStatus()
        if (ok) {
            const resp = await store.dispatch('auth/getRankingTh', { page, rankingType, townHall, city })
            return resp
        }
        else {
            return { ok: false}
        }
    }
    
    const getRankingSingleTownHall = async ( rankingType, townHall ) => {
        const { ok } = await checkAuthStatus()
        if (ok) {
            const resp = await store.dispatch('auth/getRankingSingleTownHall', { rankingType, townHall })
            return resp
        }
        else {
            return { ok: false}
        }
    }

    const getGift = async () => {
        await store.dispatch('auth/getGift')
    }
    
    const getQuestion = async (correct) => {
        await store.dispatch('auth/getQuestion', { correct })
    }
    
    const resetLocalRanking = async () => {
        await store.dispatch('auth/resetLocalRanking')
    }

    const getNeighborPoints = async ( page, neighbor ) => {
        const { ok } = await checkAuthStatus()
        if (ok) {
            const resp = await store.dispatch('auth/getNeighborPoints', { page, neighbor })
            return resp
        }
        else {
            return { ok: false}
        }
    }

    return {
        checkAuthStatus,
        getUser,
        loginUser,
        logout,
        deleteNotification,
        checkNotification,
        resetPass,
        newPass,
        updateData,
        updatePassword,
        getTutorials,
        resendCard,
        getRanking,
        getRankingSingleNeighbor,
        getLevelRank,
        getRankingTh,
        getRankingSingleTownHall,
        getGift,
        getQuestion,
        resetLocalRanking,
        getNeighborPoints,

        user: computed(() => store.getters['auth/user']),
        authStatus: computed(() => store.getters['auth/currentState']),
        group: computed(() => store.getters['auth/getGroup']),
        groups: computed(() => store.getters['auth/getGroups']),
        username: computed(() => store.getters['auth/username']),
        notifications: computed(() => store.getters['auth/notifications']),
        uncheckedNotifications: computed(() => store.getters['auth/uncheckedNotifications']),
        dateJoined: computed(() => store.getters['auth/dateJoined']), 
        sessionExpired: computed(() => store.getters['auth/sessionExpired']),  
        tutorials: computed(() => store.getters['auth/tutorials']),
        ranking: computed(() => store.getters['auth/ranking']),
        rankingFiltered: computed(() => store.getters['auth/rankingFiltered']),
        neighborRanking: computed(() => store.getters['auth/neighborRanking']),
        levelRank: computed(() => store.getters['auth/levelRank']),
        townHallsList: computed(() => store.getters['auth/townHallsList']),
        neighborPoints: computed(() => store.getters['auth/neighborPoints'])
    }
}

export default useAuth