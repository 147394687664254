import store from '@/store'
import { Role } from '@/helpers/role'
import indexedDB from '@/indexedDB'

const isAuthenticatedGuard = async (to, from, next) => {

    const { ok } = await store.dispatch('auth/checkAuthentication')

    if (ok) {
        let roles = []
        const vuex = await indexedDB.get('vuex')
        if(JSON.parse(vuex).auth.user.is_staff) roles = ['admin']
        else roles = JSON.parse(vuex).auth.user.groups.map((group) => Role[group]);

        const index = to.path.slice(1).indexOf('/')

        let role = to.path.substring(1, index + 1)

        if (role.length < 2) role = to.path.slice(1)
        
        if (roles.includes(role)) next()
        else if (!roles.includes(role) && roles.includes('gestor') && role == 'ayuntamiento') next()
        else next({ path: '/' + roles[0] })
    }
    else next({ name: 'auth' })
}


export default isAuthenticatedGuard