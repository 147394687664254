import compoboxApi from '@/api/compobox'

export const getAdmin = async ({ commit }) => {

    try {

        const { data } = await compoboxApi.get('/administrator/', {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })

        const admin = data[0]
        commit('setAdmin', { admin })

        return { ok: true }

    } catch (error) {
        if (error.response != undefined) {
            return { ok: false, message: error.response.data }
        } else {
            return { ok: false, message: error.message }
        }
    }

}

export const createMachine = async ({ commit }, machine) => {

    try {

        const { data } = await compoboxApi.post('/machine/', machine, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })

        commit('addMachine', { data })

        return { ok: true }

    } catch (error) {
        if (error.response != undefined) {
            return { ok: false, message: error.response.data }
        } else {
            return { ok: false, message: error.message }
        }
    }

}

export const createTutorial = async ({ commit },form) => {

    try {

        await compoboxApi.post('/tutorial/', form, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })

        return { ok: true }

    } catch (error) {
        if (error.response != undefined) {
            return { ok: false, message: error.response.data }
        } else {
            return { ok: false, message: error.message }
        }
    }

}

export const createManager = async ({ commit }, { user, manager }) => {

    try {
        user.is_staff = false
        user.is_active = false
        user.groups = [1]

        const response = await compoboxApi.post('/user/', user, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })

        manager.user = response.data.id

        try {
            const { data } = await compoboxApi.post('/manager/', manager, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
                }
            })
            commit('addManager', { data })

            return { ok: true }
        } catch (error) {

            await compoboxApi.delete('/user/' + response.data.id + '/', {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
                }
            })

            if (error.response != undefined) {
                return { ok: false, message: error.response.data }
            } else {
                return { ok: false, message: error.message }
            }
        }

    } catch (error) {
        if (error.response != undefined) {
            return { ok: false, message: error.response.data }
        } else {
            return { ok: false, message: error.message }
        }
    }

}

export const createTownHall = async ({ commit }, { user, th }) => {

    try {
        user.is_staff = false
        user.is_active = false
        user.groups = [2]

        const response = await compoboxApi.post('/user/', user, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })

        th.user = response.data.id

        try {
            const { data } = await compoboxApi.post('/townhall/', th, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
                }
            })
            commit('addTownHall', { data })

            return { ok: true }
        } catch (error) {

            await compoboxApi.delete('/user/' + response.data.id + '/', {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
                }
            })

            if (error.response != undefined) {
                return { ok: false, message: error.response.data }
            } else {
                return { ok: false, message: error.message }
            }
        }

    } catch (error) {
        if (error.response != undefined) {
            return { ok: false, message: error.response.data }
        } else {
            return { ok: false, message: error.message }
        }
    }

}

export const createNeighbor = async ({ commit }, { user, neighbor }) => {

    try {
        user.is_staff = false
        user.is_active = true
        user.groups = [3]

        const response = await compoboxApi.post('/user/', user, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })

        neighbor.user = response.data.id

        try {
            const { data } = await compoboxApi.post('/neighbor/', neighbor, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
                }
            })
            commit('addNeighbor', { data })

            return { ok: true }
        } catch (error) {

            await compoboxApi.delete('/user/' + response.data.id + '/', {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
                }
            })

            if (error.response != undefined) {
                return { ok: false, message: error.response.data }
            } else {
                return { ok: false, message: error.message }
            }
        }

    } catch (error) {
        if (error.response != undefined) {
            return { ok: false, message: error.response.data }
        } else {
            return { ok: false, message: error.message }
        }
    }

}

export const createTechnician = async ({ commit }, { user, technician }) => {

    try {
        user.is_staff = false
        user.is_active = true
        user.groups = [4]

        const response = await compoboxApi.post('/user/', user, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })

        technician.user = response.data.id

        try {
            const { data } = await compoboxApi.post('/technician/', technician, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
                }
            })
            commit('addTechnician', { data })

            return { ok: true }
        } catch (error) {

            await compoboxApi.delete('/user/' + response.data.id + '/', {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
                }
            })

            if (error.response != undefined) {
                return { ok: false, message: error.response.data }
            } else {
                return { ok: false, message: error.message }
            }
        }

    } catch (error) {
        if (error.response != undefined) {
            return { ok: false, message: error.response.data }
        } else {
            return { ok: false, message: error.message }
        }
    }

}

export const createFarmer = async ({ commit }, { user, farmer }) => {

    try {
        user.is_staff = false
        user.is_active = true
        user.groups = [6]

        const response = await compoboxApi.post('/user/', user, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })

        farmer.user = response.data.id

        try {
            const { data } = await compoboxApi.post('/farmer/', farmer, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
                }
            })
            commit('addFarmer', { data })

            return { ok: true }
        } catch (error) {

            await compoboxApi.delete('/user/' + response.data.id + '/', {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
                }
            })

            if (error.response != undefined) {
                return { ok: false, message: error.response.data }
            } else {
                return { ok: false, message: error.message }
            }
        }

    } catch (error) {
        if (error.response != undefined) {
            return { ok: false, message: error.response.data }
        } else {
            return { ok: false, message: error.message }
        }
    }

}

export const deactivateUser = async ({ commit }, form) => {

    try {
        const payload = {
            is_active: false,
            username: 'Deleted' + form.id.toString(),
            first_name: form.motivo,
            last_name: '',
            email: 'Deleted' + form.id.toString() + '@mail.deleted',
            phone: 'Deleted',
            address: {
                StreetAndNumber: 'Deleted',
                OtherInfo: 'Deleted',
            }
        }

        await compoboxApi.patch('/user/' + form.id + '/', payload, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })



        switch (form.group) {
            case 1:
                commit('deleteManager', form.id)
                break
            case 2:
                commit('deleteTownHall', form.id)
                break
            case 3:
                commit('deleteNeighbor', form.id)
                break
            case 4:
                commit('deleteTechnician', form.id)
                break
            case 5:
                commit('deleteBussiness', form.id)
                break
            case 6:
                commit('deleteFarmer', form.id)
                break
        }

        return { ok: true }

    } catch (error) {
        console.log(error.response)
        if (error.response != undefined) {
            return { ok: false, message: error.response.data }
        } else {
            return { ok: false, message: error.message }
        }
    }

}

export const deactivateMachine = async ({ commit }, form) => {

    try {
        const payload = {
            is_active: false,
            deactivation_reason: form.motivo,
            town_hall: null
        }


        await compoboxApi.patch('/machine/' + form.id + '/', payload, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })

        commit('deleteMachine', form.id)

        return { ok: true }

    } catch (error) {
        if (error.response != undefined) {
            return { ok: false, message: error.response.data }
        } else {
            return { ok: false, message: error.message }
        }
    }

}

export const deleteMachine = async ({ commit }, id) => {

    try {

        await compoboxApi.delete('/machine/' + id, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })

        commit('deleteMachine', id)

        return { ok: true }

    } catch (error) {
        if (error.response != undefined) {
            return { ok: false, message: error.response.data }
        } else {
            return { ok: false, message: error.message }
        }
    }

}

export const deleteUser = async ({ commit }, payload) => {

    try {

        await compoboxApi.delete('/user/' + payload.id, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })

        switch (payload.role) {
            case 1:
                commit('deleteManager', payload.id)
                break
            case 2:
                commit('deleteTownHall', payload.id)
                break
            case 3:
                commit('deleteNeighbor', payload.id)
                break
            case 4:
                commit('deleteTechnician', payload.id)
                break
            case 5:
                commit('deleteBussiness', payload.id)
                break
            case 6:
                commit('deleteFarmer', payload.id)
                break
            case 0:
                commit('deleteNewUser', payload.id)
                break
        }

        return { ok: true }

    } catch (error) {
        if (error.response != undefined) {
            return { ok: false, message: error.response.data }
        } else {
            return { ok: false, message: error.message }
        }
    }

}

export const deleteTutorial = async ({ commit }, id) => {

    try {

        await compoboxApi.delete('/tutorial/' + id, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })

        return { ok: true }

    } catch (error) {
        if (error.response != undefined) {
            return { ok: false, message: error.response.data }
        } else {
            return { ok: false, message: error.message }
        }
    }

}

export const updateManager = async ({ commit }, { user, manager }) => {

    try {

        await compoboxApi.patch('/user/' + user.id + '/', user, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })

        const { data } = await compoboxApi.patch('/manager/' + manager.id + '/', manager, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })

        commit('updateManager', { data })

        return { ok: true }

    } catch (error) {
        if (error.response != undefined) {
            return { ok: false, message: error.response.data }
        } else {
            return { ok: false, message: error.message }
        }
    }

}

export const updateTownHall = async ({ commit }, { user, th }) => {

    try {

        await compoboxApi.patch('/user/' + user.id + '/', user, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })

        const { data } = await compoboxApi.patch('/townhall/' + th.id + '/', th, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })

        commit('updateTownHall', { data })

        return { ok: true }

    } catch (error) {
        if (error.response != undefined) {
            return { ok: false, message: error.response.data }
        } else {
            return { ok: false, message: error.message }
        }
    }

}

export const updateNeighbor = async ({ commit }, { user, neighbor }) => {

    try {

        await compoboxApi.patch('/user/' + user.id + '/', user, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })

        const { data } = await compoboxApi.patch('/neighbor/' + neighbor.id + '/', neighbor, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })

        commit('updateNeighbor', { data })

        return { ok: true }

    } catch (error) {
        if (error.response != undefined) {
            return { ok: false, message: error.response.data }
        } else {
            return { ok: false, message: error.message }
        }
    }

}

export const updateTechnician = async ({ commit }, { user, technician }) => {

    try {

        await compoboxApi.patch('/user/' + user.id + '/', user, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })

        const { data } = await compoboxApi.patch('/technician/' + technician.id + '/', technician, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })

        commit('updateTechnician', { data })

        return { ok: true }

    } catch (error) {
        if (error.response != undefined) {
            return { ok: false, message: error.response.data }
        } else {
            return { ok: false, message: error.message }
        }
    }

}

export const updateFarmer = async ({ commit }, { user, farmer }) => {

    try {

        await compoboxApi.patch('/user/' + user.id + '/', user, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })

        const { data } = await compoboxApi.patch('/farmer/' + farmer.id + '/', farmer, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })

        commit('updateFarmer', { data })

        return { ok: true }

    } catch (error) {
        if (error.response != undefined) {
            return { ok: false, message: error.response.data }
        } else {
            return { ok: false, message: error.message }
        }
    }

}

export const updateMachine = async ({ commit }, machine) => {

    try {

        const { data } = await compoboxApi.patch('/machine/' + machine.id + '/', machine, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })

        commit('updateMachine', { data })

        return { ok: true }

    } catch (error) {
        if (error.response != undefined) {
            return { ok: false, message: error.response.data }
        } else {
            return { ok: false, message: error.message }
        }
    }

}

export const updateTutorial = async ({ commit }, form) => {

    try {

        await compoboxApi.patch('/tutorial/' + form.id + '/', form, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })

        return { ok: true }

    } catch (error) {
        if (error.response != undefined) {
            return { ok: false, message: error.response.data }
        } else {
            return { ok: false, message: error.message }
        }
    }

}

export const confirmUser = async ({ commit }, form) => {

    try {

        const user = {
            is_active: true
        }

        await compoboxApi.patch('/user/' + form.id + '/', user, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })

        form.neighbor.user = form.id

        const { data } = await compoboxApi.post('/neighbor/', form.neighbor, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })

        commit('addNeighbor', { data })
        commit('deleteNewUser', form.id)

        return { ok: true }

    } catch (error) {
        if (error.response != undefined) {
            return { ok: false, message: error.response.data }
        } else {
            return { ok: false, message: error.message }
        }
    }

}

export const addRoleTechnician = async ({ commit }, { user, technician }) => {

    try {

        await compoboxApi.patch('/user/' + user.id + '/', user, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })

        const { data } = await compoboxApi.post('/technician/', technician, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })

        commit('addTechnician', { data })

        return { ok: true }

    } catch (error) {
        console.log(error.response != undefined)
        if (error.response != undefined) {
            return { ok: false, message: error.response.data }
        } else {
            return { ok: false, message: error.message }
        }
    }

}

export const addRoleNeighbor = async ({ commit }, { user, neighbor }) => {

    try {

        await compoboxApi.patch('/user/' + user.id + '/', user, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })

        const { data } = await compoboxApi.post('/neighbor/', neighbor, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })

        commit('addNeighbor', { data })

        return { ok: true }

    } catch (error) {
        console.log(error.response != undefined)
        if (error.response != undefined) {
            return { ok: false, message: error.response.data }
        } else {
            return { ok: false, message: error.message }
        }
    }

}

export const addRoleTownHall = async ({ commit }, { user, townHall }) => {

    try {

        await compoboxApi.patch('/user/' + user.id + '/', user, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })

        const { data } = await compoboxApi.post('/townhall/', townHall, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })

        commit('addTownHall', { data })

        return { ok: true }

    } catch (error) {
        console.log(error.response != undefined)
        if (error.response != undefined) {
            return { ok: false, message: error.response.data }
        } else {
            return { ok: false, message: error.message }
        }
    }

}

export const addRoleManager = async ({ commit }, { user, manager }) => {

    try {

        await compoboxApi.patch('/user/' + user.id + '/', user, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })

        const { data } = await compoboxApi.post('/manager/', manager, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })

        commit('addManager', { data })

        return { ok: true }

    } catch (error) {
        console.log(error.response != undefined)
        if (error.response != undefined) {
            return { ok: false, message: error.response.data }
        } else {
            return { ok: false, message: error.message }
        }
    }

}

export const addRoleFarmer = async ({ commit }, { user, farmer }) => {

    try {

        await compoboxApi.patch('/user/' + user.id + '/', user, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })

        const { data } = await compoboxApi.post('/farmer/', farmer, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })

        commit('addFarmer', { data })

        return { ok: true }

    } catch (error) {
        console.log(error.response != undefined)
        if (error.response != undefined) {
            return { ok: false, message: error.response.data }
        } else {
            return { ok: false, message: error.message }
        }
    }

}

export const addRoleBusiness = async ({ commit }, { user, business }) => {

    try {

        await compoboxApi.patch('/user/' + user.id + '/', user, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })


        await compoboxApi.post('/business/', business, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })

        //To do:
        //commit('addBusiness', { data })

        return { ok: true }

    } catch (error) {
        console.log(error.response != undefined)
        if (error.response != undefined) {
            return { ok: false, message: error.response.data }
        } else {
            return { ok: false, message: error.message }
        }
    }

}

export const createVisit = async ({ commit }, visit) => {

    try {
        
        const {data} = await compoboxApi.post('/v-sheet/', visit, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })
        
        commit('addVisit', {data} )

        return { ok: true }

    } catch (error) {
        if(error.response != undefined){
            return { ok: false, message: error.response.data }
        }else{
            return { ok: false, message: error.message }
        } 
    }

}

export const createMachineSensors = async ({ commit }, form) => {

    try {

        await compoboxApi.post('/machine-sensors/', form, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })

        return { ok: true }

    } catch (error) {
        if (error.response != undefined) {
            return { ok: false, message: error.response.data }
        } else {
            return { ok: false, message: error.message }
        }
    }

}


export const getUses = async ({ commit },{machine=null, townHall=null}) => {
    let url = "/machine-uses/"
    try {
        if (machine) {
            url += "?machine=" + String(machine);
        }
        if (townHall) {
            url += "?townhall=" + String(townHall);
        }

        const { data } = await compoboxApi.get(url, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })

        const uses = data[0]
        commit('setUses', uses )

        return { ok: true }

    } catch (error) {
        if (error.response != undefined) {
            return { ok: false, message: error.response.data }
        } else {
            return { ok: false, message: error.message }
        }
    }

}