import {Role} from '@/helpers/role'
import { useRoute } from "vue-router";

export const currentState = ( state ) => {
    return state.status
}

export const username = ( state ) => {
    return state.user?.username || ''
}

export const dateJoined = ( state ) => {
    return state.user?.date_joined.substring(0,10) || ''
}

export const user = ( state ) => {
    return state.user || null
}

export const getGroup = ( state ) => {
    if(state.user?.is_staff === true) return Role[7]
    if(state.user?.groups){
        let role = null
        if(state.user.groups.length === 1) role= Role[state.user.groups[0]] 
        else if(state.user.groups.length > 1){
            return useRoute().name.substring(0, useRoute().name.indexOf('-'))
        }
        else role=Role[0]
        return role 
    }
    else{
        return Role[0]
    }
}

export const getGroups = ( state ) => { 
    return state.user?.groups
}

export const notifications = ( state ) => {
    return state.user?.notifications || []
}

export const uncheckedNotifications = ( state ) => {
    const number = state.user?.notifications.filter(item => item.read === false).length
    return number !== 0 ? number : ''
}

export const sessionExpired = ( state ) => {
    return state.sessionExpired
}

export const tutorials = ( state ) => {
    return state.tutorials || null
}

export const ranking = ( state ) => {
    return state.ranking || null
}

export const rankingFiltered = ( state ) => {
    return state.rankingFiltered || null
}

export const neighborRanking = ( state ) => {
    return state.neighborRanking || null
}

export const levelRank = ( state ) => {
    return state.levelRank || null
}

export const townHallsList = ( state ) => {
    return state.townHallsList || null
}