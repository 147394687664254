import compoboxApi from '@/api/compobox'

export const getAyuntamiento = async ({ commit }) => {

    try {

        const { data } = await compoboxApi.get('/townhall/',{
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('accessToken')}` 
            }
        })

        const ayuntamiento = data[0]
        commit('setAyuntamiento', { ayuntamiento })

        return { ok: true }

    } catch (error) {
        if(error.response != undefined){
            return { ok: false, message: error.response.data }
        }else{
            return { ok: false, message: error.message }
        } 
    }

}

export const createNeighbor = async ({ commit, state }, {user, vecino}) => {

    try {
        user.is_staff = false
        user.is_active = true
        user.groups = [3]

        const response = await compoboxApi.post('/user/', user,{
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('accessToken')}` 
            }
        })
        
        vecino.user = response.data.id
        vecino.town_hall = state.ayuntamiento.id

        try {
            const { data } = await compoboxApi.post('/neighbor/', vecino, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
                }
            })
            commit('addNeighbor', { data })

            return { ok: true }
        } catch (error) {

            await compoboxApi.delete('/user/' + response.data.id + '/', {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
                }
            })

            if (error.response != undefined) {
                return { ok: false, message: error.response.data }
            } else {
                return { ok: false, message: error.message }
            }
        }

    } catch (error) {
        if(error.response != undefined){
            return { ok: false, message: error.response.data }
        }else{
            return { ok: false, message: error.message }
        } 
    }

}

export const updateAyuntamiento = async ({ commit }, reg) => {

    try {
        
        const {data} = await compoboxApi.patch('/townhall/' + reg.id +'/', reg, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })
        
        const ayuntamiento = data
        commit('setAyuntamiento', { ayuntamiento })

        return { ok: true }

    } catch (error) {
        if(error.response != undefined){
            return { ok: false, message: error.response.data }
        }else{
            return { ok: false, message: error.message }
        } 
    }

}

export const updateNeighbor = async ({ commit }, { user, neighbor }) => {

    try {

        await compoboxApi.patch('/user/' + user.id + '/', user, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })

        const { data } = await compoboxApi.patch('/neighbor/' + neighbor.id + '/', neighbor, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })

        commit('updateNeighbor', { data })

        return { ok: true }

    } catch (error) {
        if (error.response != undefined) {
            return { ok: false, message: error.response.data }
        } else {
            return { ok: false, message: error.message }
        }
    }

}

export const deactivateUser = async ({ commit }, form) => {

    try {
        const payload = {
            is_active: false,
            username: 'Deleted' + form.id.toString(),
            first_name: form.motivo,
            last_name: '',
            email: 'Deleted' + form.id.toString() + '@mail.deleted',
            phone: 'Deleted',
            address: {
                StreetAndNumber: 'Deleted',
                OtherInfo: 'Deleted',
            }
        }

        await compoboxApi.patch('/user/' + form.id + '/', payload, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })
        //const user = data

        switch (form.group) {
            case 3:
                commit('deleteNeighbor', form.id)
                break
            case 4:
                commit('deleteTechnician', form.id)
                break
            case 5:
                commit('deleteBussiness', form.id)
                break
            case 6:
                commit('deleteFarmer', form.id)
                break
        }

        return { ok: true }

    } catch (error) {
        if (error.response != undefined) {
            return { ok: false, message: error.response.data }
        } else {
            return { ok: false, message: error.message }
        }
    }

}

export const deactivateMachine = async ({ commit }, form) => {

    try {
        const payload = {
            is_active: false,
            deactivation_reason: form.motivo,
            town_hall: null
        }

        await compoboxApi.patch('/machine/' + form.id + '/', payload, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })
        //const user = data
        commit('deleteMachine', form.id)

        return { ok: true }

    } catch (error) {
        if (error.response != undefined) {
            return { ok: false, message: error.response.data }
        } else {
            return { ok: false, message: error.message }
        }
    }

}

export const updateAliasMachine = async ({ commit }, { machine, alias }) => {

    try {

        const { data } = await compoboxApi.patch('/machine/' + machine + '/', alias, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })
        
        commit('updateAliasMachine', { data })

        return { ok: true }

    } catch (error) {
        if (error.response != undefined) {
            return { ok: false, message: error.response.data }
        } else {
            return { ok: false, message: error.message }
        }
    }

}

