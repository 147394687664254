export default {

    name: 'agricultor',
    component: () => import(/* webpackChunkName: "vecino" */ '../layouts/AgricultorLayout.vue'),
    children:[
        {
            path: '',
            name: 'agricultor-resumen',
            component: () => import(/* webpackChunkName: "agricultor-resumen" */ '../views/ResumenView.vue'),
        },
        {
            path: '/agricultor/tutoriales',
            name: 'agricultor-tutoriales',
            component: () =>
                import ( /* webpackChunkName: "ayuntamiento-vecinos" */ '@/modules/shared/pages/TutorialesView.vue'),
        },
        {
            path: '/agricultor/mensajes',
            name: 'agricultor-mensajes',
            props: true,
            component: () =>
                import ( /* webpackChunkName: "ayuntamiento-vecinos" */ '@/modules/shared/pages/MensajesView.vue'),
        },
        {
            path: '/agricultor/datos',
            name: 'agricultor-datos',
            component: () => import(/* webpackChunkName: "ayuntamiento-vecinos" */ '@/modules/shared/pages/DatosView.vue'),
        },
    ]

}