
export const setEmpresa = ( state, { empresa }) => {

    if ( empresa ) {
        state.empresa = empresa
    }
}

export const logout = (state) => {   
    state.empresa = null
}