export default {

    name: 'ayuntamiento',
    component: () =>
        import( /* webpackChunkName: "vecino" */ '../layouts/AyuntamientoLayout.vue'),
    children: [{
        path: '',
        name: 'ayuntamiento-resumen',
        component: () =>
            import( /* webpackChunkName: "ayuntamiento-resumen" */ '../views/ResumenView.vue'),
    },
    {
        path: '/ayuntamiento/compobox',
        name: 'ayuntamiento-compobox',
        component: () =>
            import( /* webpackChunkName: "ayuntamiento-compobox" */ '../views/CompoboxView.vue'),
    },
    {
        path: '/ayuntamiento/vecinos',
        name: 'ayuntamiento-vecinos',
        component: () =>
            import( /* webpackChunkName: "ayuntamiento-vecinos" */ '../views/VecinosView.vue'),
    },
    {
        path: '/ayuntamiento/mensajes',
        name: 'ayuntamiento-mensajes',
        props: true,
        component: () =>
            import( /* webpackChunkName: "ayuntamiento-vecinos" */ '@/modules/shared/pages/MensajesView.vue'),
    },
    {
        path: '/ayuntamiento/datos',
        name: 'ayuntamiento-datos',
        component: () =>
            import( /* webpackChunkName: "ayuntamiento-vecinos" */ '@/modules/shared/pages/DatosView.vue'),
    },
    {
        path: '/ayuntamiento/ranking',
        name: 'ayuntamiento-ranking',
        component: () =>
            import( /* webpackChunkName: "ayuntamiento-vecinos" */ '@/modules/shared/pages/RankingView.vue'),
    },
    {
        path: '/ayuntamiento/monedero',
        name: 'ayuntamiento-monedero',
        component: () =>
            import( /* webpackChunkName: "ayuntamiento-monedero" */ '../views/MonederoView.vue'),
    },
    {
        path: '/ayuntamiento/informes',
        name: 'ayuntamiento-informes',
        component: () =>
            import( /* webpackChunkName: "ayuntamiento-informes" */ '../views/InformesView.vue'),
    },
    {
        path: '/ayuntamiento/tutoriales',
        name: 'ayuntamiento-tutoriales',
        component: () =>
            import( /* webpackChunkName: "ayuntamiento-vecinos" */ '@/modules/shared/pages/TutorialesView.vue'),
    },
    ]

}