
export const setAgricultor = ( state, { agricultor }) => {

    if ( agricultor ) {
        state.agricultor = agricultor
    }
}

// export const updateHistorical = ( state, { data }) => {
    
//     const ind = state.agricultor.machines_historical.indexOf(state.agricultor.machines_historical.find(item => item.id === data.id))
//     state.agricultor.machines_historical[ind] = data

// }

// export const addHistorical = ( state, { data }) => {
    
//     state.agricultor.machines_historical.push(data)
//     state.agricultor.machines_historical.sort((a, b) => {
//         return new Date(b.date) - new Date(a.date);
//     });

// }

export const logout = (state) => {   
    state.agricultor = null
}
