
export const loginUser = async( state, { access, refresh }) => {

    if ( access ) {
        localStorage.setItem( 'accessToken', access )
        state.accessToken = access
    }

    if ( refresh ) {
        localStorage.setItem( 'refreshToken', refresh )
        state.refreshToken = refresh
    }
    state.sessionExpired = false
    state.status = 'authenticated'
}

export const setUser = ( state, { user }) => {

    if ( user ) {
        state.user = user
    }
}

export const deleteNotification = ( state, { id }) => {

    state.user.notifications.splice( state.user.notifications.findIndex(item => item.id === id), 1)

}

export const checkNotification = ( state, { id }) => {

    state.user.notifications.find(item => item.id === id).read = true

}


export const logout = (state) => {
    
    state.user = null
    state.accessToken = null
    state.refreshToken = null
    state.status = 'not-authenticated'
    state.tutorials = null
    state.ranking = {
        local: {
            thisMonth: {},
            lastMonth: {},
            thisYear: {},
        },
        national: {
            thisMonth: {},
            lastMonth: {},
            thisYear: {},
        },
        townhalls: {
            thisMonth: {},
            lastMonth: {},
            thisYear: {},
        }
    }
    state.neighborRanking = {
        local: {},
        national: {},
        townhalls: {},
    }
    state.rankingFiltered =  null
    state.levelRank =  null
    state.rankingSingleTownHall =  null
    state.townHallsList = null

    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
}

export const sessionExpired = (state) => {
    state.sessionExpired = true
}

export const setTutorials = ( state, data ) => {

    if ( data ) {
        state.tutorials = data
    }
}

export const setRanking = ( state, { data, typeLocal, typeDate, page } ) => {

    if ( data ) {

        state.ranking[typeLocal][typeDate][`page${page}`] = data
    }
}

export const setRankingFiltered = ( state, data) => {

    if ( data ) {
        state.rankingFiltered = data
    }
}

export const setRankingSingleTownHall = ( state, data ) => {

    if ( data ) {
        state.rankingSingleTownHall = data
    }
}

export const resetRankingSingleTownHall = ( state, {} ) => {

    state.rankingSingleTownHall = null
}

export const setNeighborRanking = (state, { data, typeLocal, typeDate }) => {

    if ( data ) {
        state.neighborRanking[typeLocal][typeDate] = data
    }
}

export const resetNeighborRanking = (state, {}) => {

    state.neighborRanking = {
        local: {},
        national: {},
        townhalls: {},
    }
}

export const setLevelRank = (state, data) => {

    if ( data ) {
        state.levelRank = data
    }
}

export const resetLevelRank = (state, {}) => {

    state.levelRank = null

}

export const initializeRanking = (state, {}) => { // Chapuza para que state.ranking exista desde el principio
    if (!state.ranking){
        state.ranking = {
            local: {
                thisMonth: {},
                lastMonth: {},
                thisYear: {},
            },
            national: {
                thisMonth: {},
                lastMonth: {},
            thisYear: {},
          },
          townhalls: {
              thisMonth: {},
              lastMonth: {},
              thisYear: {},
            },
        };
    }
};

export const resetRanking = (state, {}) => {
    state.ranking = {
        local: {
            thisMonth: {},
            lastMonth: {},
            thisYear: {},
        },
        national: {
            thisMonth: {},
            lastMonth: {},
        thisYear: {},
        },
        townhalls: {
            thisMonth: {},
            lastMonth: {},
            thisYear: {},
        },
    };    
};

export const resetLocalRanking = (state, {}) => {
    state.ranking.local = {
        thisMonth: {},
        lastMonth: {},
        thisYear: {},
    }
};

export const setTownHallsList = (state, data) => {

    if ( data ) {
        state.townHallsList = data
    }
}

export const setNeighborPoints = (state, data) => {

    if ( data ) {
        state.neighborPoints = data
    }
}