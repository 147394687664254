import compoboxApi from '@/api/compobox'

export const getEmpresa = async ({ commit }) => {

    try {

        const { data } = await compoboxApi.get('/business/',{
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('accessToken')}` 
            }
        })

        const empresa = data[0]
        commit('setEmpresa', { empresa })

        return { ok: true }

    } catch (error) {
        return { ok: false, message: error.message }
    }

}

