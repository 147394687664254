import compoboxApi from '@/api/compobox'
import moment from 'moment';

export const signInUser = async ({ commit }, user) => {

    const { username, password } = user

    try {

        const { data } = await compoboxApi.post('/token/', { username, password })
        const { access, refresh } = data

        commit('loginUser', { access, refresh })

        return { ok: true }

    } catch (error) {
        if(error.response != undefined){
            return { ok: false, message: error.response.data }
        }else{
            return { ok: false, message: error.message }
        }        
    }

}

export const getUser = async ({ commit }) => {

    try {

        const { data } = await compoboxApi.get('/user/1/', {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })

        const user = data[0]
        commit('setUser', { user })

        return { ok: true }

    } catch (error) {
        if(error.response != undefined){
            return { ok: false, message: error.response.data }
        }else{
            return { ok: false, message: error.message }
        } 
    }

}


export const checkAuthentication = async ({ commit }) => {

    const accessToken = localStorage.getItem('accessToken')
    const refresh = localStorage.getItem('refreshToken')

    if (!accessToken) {
        commit('logout')
        return { ok: false, message: 'No hay token' }
    }

    try {

        const { data } = await compoboxApi.get('/user/1/', {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })

        const user = data[0]
        commit('setUser', { user })

        return { ok: true }

    } catch (error) {
        try {

            const { data } = await compoboxApi.post('/token/refresh/', { refresh })
            const { access } = data

            commit('loginUser', { access, refresh })

            return { ok: true }

        } catch (error) {
            commit('sessionExpired')
            if(error.response != undefined){
                return { ok: false, message: error.response.data }
            }else{
                return { ok: false, message: error.message }
            } 
        }
    }
}

export const deleteNotification = async ({ commit }, id) => {

    try {
        await compoboxApi.delete('/notification/' + id, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })

        commit('deleteNotification', { id })

        return { ok: true }

    } catch (error) {
        if(error.response != undefined){
            return { ok: false, message: error.response.data }
        }else{
            return { ok: false, message: error.message }
        } 
    }

}

export const checkNotification = async ({ commit }, id) => {

    try {
        await compoboxApi.patch('/notification/' + id +'/', {read: true}, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })

        commit('checkNotification', { id })

        return { ok: true }

    } catch (error) {
        if(error.response != undefined){
            return { ok: false, message: error.response.data }
        }else{
            return { ok: false, message: error.message }
        } 
    }

}

export const resetPass = async ({ commit }, mail ) => {

    try {
        await compoboxApi.post('/users/password_reset/', {email: mail})

        return { ok: true }

    } catch (error) {
        if(error.response != undefined){
            return { ok: false, message: error.response.data }
        }else{
            return { ok: false, message: error.message }
        } 
    }

}

export const newPass = async ( { commit }, form) => {

    try {
        await compoboxApi.post('/users/password_reset/confirm/', form)

        return { ok: true }

    } catch (error) {
        if(error.response != undefined){
            return { ok: false, message: error.response.data }
        }else{
            return { ok: false, message: error.message }
        } 
    }

}

export const updateData = async ({ commit }, form) => {

    try {
        const {data} = await compoboxApi.patch('/user/' + form.id +'/', form, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })

        const user = data
        commit('setUser', { user })

        return { ok: true }

    } catch (error) {
        if(error.response != undefined){
            return { ok: false, message: error.response.data }
        }else{
            return { ok: false, message: error.message }
        } 
    }

}

export const updatePassword = async ({ commit }, form) => {

    try {
        await compoboxApi.patch('/users/change_password/', form, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })

        return { ok: true }

    } catch (error) {
        if(error.response != undefined){
            return { ok: false, message: error.response.data }
        }else{
            return { ok: false, message: error.message }
        } 
    }

}

export const getTutorials = async ({ commit }) => {

    try {
        const {data} = await compoboxApi.get('/tutorial/', {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })
        commit('setTutorials',  data )

        return { ok: true }

    } catch (error) {
        if(error.response != undefined){
            return { ok: false, message: error.response.data }
        }else{
            return { ok: false, message: error.message }
        } 
    }

}

export const resendCard = async ({ commit }, data) => {

    try {
        await compoboxApi.patch('/resend_card/', data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })

        return { ok: true }

    } catch (error) {
        if(error.response != undefined){
            return { ok: false, message: error.response.data }
        }else{
            return { ok: false, message: error.message }
        } 
    }

}

export const getRanking = async ({ commit },{ page, rankingType, townHall=null, username=null}) => {
    var typeLocal = ''
    var typeDate = ''
    
    try {
        if (rankingType === moment().format('MM-YYYY')) {
            typeDate = 'thisMonth'
        } else if (rankingType === moment().subtract(1,'month').format('MM-YYYY')) {
            typeDate = 'lastMonth'
        } else if (rankingType === moment().format('YYYY')) {
            typeDate = 'thisYear'
        }

        let url = "/neighbor-points/?page=" + page + "&ranking_type=" + rankingType
        if (townHall) {
            url += "&town_hall=" + String(townHall);
            typeLocal = 'local'
        } else {
            typeLocal = 'national'
        }
        if (username) {
            url = "/neighbor-points/?page=1" + "&ranking_type=" + rankingType + "&username=" + username;
        }
        const { data } = await compoboxApi.get(url, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
        })

        if (!username) {
            commit('initializeRanking', {})
            commit('setRanking', {data, typeLocal, typeDate, page } )
        } else {
            commit('setRankingFiltered', data)
        }

        return { ok: true }

    } catch (error) {
        if(error.response != undefined){
            return { ok: false, message: error.response.data }
        }else{
            return { ok: false, message: error.message }
        }
    }
    
}

export const getRankingSingleNeighbor = async ({ commit }, { neighborId, rankingType, townHall=null }) => {
    var typeLocal = ''
    var typeDate = ''
    try {
        let url = `/neighbor-points/?neighbor=${neighborId}&ranking_type=${rankingType}`;

        if (rankingType === moment().format('MM-YYYY')) {
            typeDate = 'thisMonth'
        } else if (rankingType === moment().subtract(1,'month').format('MM-YYYY')) {
            typeDate = 'lastMonth'
        } else if (rankingType === moment().format('YYYY')) {
            typeDate = 'thisYear'
        }
        
        if (townHall) {
            url += "&town_hall=" + String(townHall);
            typeLocal = 'local'
        } else {
            typeLocal = 'national'
        }
      const { data } = await compoboxApi.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });

      commit('setNeighborRanking', {data, typeLocal, typeDate } );
  
      return { ok: true, data };
    } catch (error) {
      if (error.response != undefined) {
        return { ok: false, message: error.response.data };
      } else {
        return { ok: false, message: error.message };
      }
    }
  };


export const getLevelRank = async ({ commit }, { neighborId}) => {
    try {
      const { data } = await compoboxApi.get(`/badges/?neighbor=${neighborId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
  
      commit('setLevelRank', data);


  
      return { ok: true, data };
    } catch (error) {
      if (error.response != undefined) {
        return { ok: false, message: error.response.data };
      } else {
        return { ok: false, message: error.message };
      }
    }
  };


export const getRankingTh = async ({ commit }, { page, rankingType, townHall=null, city=null } ) => {
    var typeDate = ''
    var typeLocal = 'townhalls'
    try {
        if (rankingType === moment().format('MM-YYYY')) {
            typeDate = 'thisMonth'
        } else if (rankingType === moment().subtract(1,'month').format('MM-YYYY')) {
            typeDate = 'lastMonth'
        } else if (rankingType === moment().format('YYYY')) {
            typeDate = 'thisYear'
        }
        let url = "/town-hall-points/?page=" + page + "&ranking_type=" + rankingType
        if (townHall) {
            url += "&town_hall=" + String(townHall);
        }
        if (city) {
            url += "&city=" + city;
        }
        const { data } = await compoboxApi.get(url, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
        })
        if (!city) {
            commit('setRanking', {data, typeLocal, typeDate, page } )
        } else {
            commit('setRankingFiltered', data)
        }

        return { ok: true }

    } catch (error) {
        if(error.response != undefined){
            return { ok: false, message: error.response.data }
        }else{
            return { ok: false, message: error.message }
        }
    }
}

export const getRankingSingleTownHall = async ({ commit }, { rankingType, townHall }) => {
    var typeDate = ''
    var typeLocal = 'townhalls'
    try {
        if (rankingType === moment().format('MM-YYYY')) {
            typeDate = 'thisMonth'
        } else if (rankingType === moment().subtract(1,'month').format('MM-YYYY')) {
            typeDate = 'lastMonth'
        } else if (rankingType === moment().format('YYYY')) {
            typeDate = 'thisYear'
        }
      const { data } = await compoboxApi.get(`/town-hall-points/?&ranking_type=${rankingType}&town_hall=${townHall}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });

        commit('setTownHallsList', data)
        commit('setNeighborRanking', {data, typeLocal, typeDate });
  
      return { ok: true, data };
    } catch (error) {
      if (error.response != undefined) {
        return { ok: false, message: error.response.data };
      } else {
        return { ok: false, message: error.message };
      }
    }
  };

export const getGift = async ({ commit }) => {

    try {

        await compoboxApi.post('/ranking-points/',{
            'action_type': 'GIFT'            
        },{
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('accessToken')}` 
            }
        })

        commit('resetRanking',{})
        commit('resetLevelRank',{})
        commit('resetNeighborRanking',{})
        commit('resetRankingSingleTownHall',{})

        return { ok: true }
        
    } catch (error) {
        if(error.response != undefined){
            return { ok: false, message: error.response.data }
        }else{
            return { ok: false, message: error.message }
        } 
    }

}

export const getQuestion = async ({ commit }, { correct }) => {

    try {
        
        await compoboxApi.post('/ranking-points/',{
            'action_type': 'QUESTION',
            'correct_answer': correct           
        },{
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('accessToken')}` 
            }
        })
        
        commit('resetRanking',{})
        commit('resetLevelRank',{})
        commit('resetNeighborRanking',{})
        commit('resetRankingSingleTownHall',{})
        
        return { ok: true }
        
    } catch (error) {
        if(error.response != undefined){
            return { ok: false, message: error.response.data }
        }else{
            return { ok: false, message: error.message }
        } 
    }
}

export const resetLocalRanking = async ({ commit }) => {
    
    commit('resetLocalRanking',{})

}

export const getNeighborPoints = async ({ commit },{ page, neighbor}) => {
    try {

        let url = "/ranking-points/?page=" + page + "&neighbor=" + neighbor + "&locked=false"
        const { data } = await compoboxApi.get(url, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
        })

        commit('setNeighborPoints', data)

        return { data }

    } catch (error) {
        if(error.response != undefined){
            return { ok: false, message: error.response.data }
        }else{
            return { ok: false, message: error.message }
        }
    }
    
}