import compoboxApi from '@/api/compobox'
import moment from 'moment';

export const getVecino = async ({ commit }) => {

    try {

        const { data } = await compoboxApi.get('/neighbor/',{
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('accessToken')}` 
            }
        })

        const vecino = data[0]
        commit('setVecino', { vecino })

        return { ok: true }

    } catch (error) {
        if(error.response != undefined){
            return { ok: false, message: error.response.data }
        }else{
            return { ok: false, message: error.message }
        } 
    }

}

export const updateVecino = async ({ commit }, form) => {

    try {
        const {data} = await compoboxApi.patch('/neighbor/' + form.id +'/', form, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })

        const vecino = data
        commit('setVecino', { vecino })

        return { ok: true }

    } catch (error) {
        if(error.response != undefined){
            return { ok: false, message: error.response.data }
        }else{
            return { ok: false, message: error.message }
        } 
    }

}

export const getQuestion = async ({ commit }) => {
    try {
        let date = moment().startOf('isoWeek').format('YYYY-MM-DD')
        const {data} = await compoboxApi.get('/question/?date=' + date, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })

        const question = data
        commit('setQuestion', { data })

        return { ok: true }

    } catch (error) {
        if(error.response != undefined){
            return { ok: false, message: error.response.data }
        }else{
            return { ok: false, message: error.message }
        } 
    }

}

