import compoboxApi from '@/api/compobox'

export const getGestor = async ({ commit }) => {

    try {

        const { data } = await compoboxApi.get('/manager/',{
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('accessToken')}` 
            }
        })

        const gestor = data[0]
        commit('setGestor', { gestor })

        return { ok: true }

    } catch (error) {
        if(error.response != undefined){
            return { ok: false, message: error.response.data }
        }else{
            return { ok: false, message: error.message }
        } 
    }

}

export const updateGestor = async ({ commit }, reg) => {

    try {
        
        const {data} = await compoboxApi.patch('/manager/' + reg.id +'/', reg, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })
        
        const gestor = data
        commit('setGestor', { gestor })

        return { ok: true }

    } catch (error) {
        if(error.response != undefined){
            return { ok: false, message: error.response.data }
        }else{
            return { ok: false, message: error.message }
        } 
    }

}

export const deactivateUser = async ({ commit }, form) => {

    try {
        const payload = {
            is_active: false,
            username: 'Deleted' + form.id.toString(),
            first_name: form.motivo,
            last_name: '',
            email: 'Deleted' + form.id.toString() + '@mail.deleted',
            phone: 'Deleted',
            address: {
                StreetAndNumber: 'Deleted',
                OtherInfo: 'Deleted',
            }
        }

        await compoboxApi.patch('/user/' + form.id + '/', payload, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })

        switch (form.group) {
            case 2:
                commit('deleteTownHall', form.id)
                break
            case 3:
                commit('deleteNeighbor', form.id)
                break
            case 4:
                commit('deleteTechnician', form.id)
                break
            case 5:
                commit('deleteBussiness', form.id)
                break
            case 6:
                commit('deleteFarmer', form.id)
                break
        }

        return { ok: true }

    } catch (error) {
        if (error.response != undefined) {
            return { ok: false, message: error.response.data }
        } else {
            return { ok: false, message: error.message }
        }
    }

}

export const updateTechnician = async ({ commit }, { user, technician }) => {

    try {

        await compoboxApi.patch('/user/' + user.id + '/', user, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })

        const { data } = await compoboxApi.patch('/technician/' + technician.id + '/', technician, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })

        commit('updateTechnician', { data })

        return { ok: true }

    } catch (error) {
        if (error.response != undefined) {
            return { ok: false, message: error.response.data }
        } else {
            return { ok: false, message: error.message }
        }
    }

}

export const updateFarmer = async ({ commit }, { user, farmer }) => {

    try {

        await compoboxApi.patch('/user/' + user.id + '/', user, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })

        const { data } = await compoboxApi.patch('/farmer/' + farmer.id + '/', farmer, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })

        commit('updateFarmer', { data })

        return { ok: true }

    } catch (error) {
        if (error.response != undefined) {
            return { ok: false, message: error.response.data }
        } else {
            return { ok: false, message: error.message }
        }
    }

}


