import compoboxApi from '@/api/compobox'

export const getAgricultor = async ({ commit }) => {

    try {

        const { data } = await compoboxApi.get('/farmer/',{
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}` 
            }
        })

        const agricultor = data[0]
        commit('setAgricultor', { agricultor })

        return { ok: true }

    } catch (error) {
        if(error.response != undefined){
            return { ok: false, message: error.response.data }
        }else{
            return { ok: false, message: error.message }
        } 
    }

}


export const createHistorical = async ({ commit }, historical) => {

    try {
        
        await compoboxApi.post('/m-historical/', historical, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })

        return { ok: true }

    } catch (error) {
        if(error.response != undefined){
            return { ok: false, message: error.response.data }
        }else{
            return { ok: false, message: error.message }
        } 
    }

}

