import compoboxApi from '@/api/compobox'

export const getTecnico = async ({ commit }) => {

    try {

        const { data } = await compoboxApi.get('/technician/',{
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('accessToken')}` 
            }
        })

        const tecnico = data[0]
        commit('setTecnico', { tecnico })

        return { ok: true }

    } catch (error) {
        if(error.response != undefined){
            return { ok: false, message: error.response.data }
        }else{
            return { ok: false, message: error.message }
        } 
    }

}

export const updateHistorical = async ({ commit }, historical) => {

    try {
        
        const {data} = await compoboxApi.patch('/m-historical/' + historical.id +'/', historical, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })
        
        commit('updateHistorical', { data })

        return { ok: true }

    } catch (error) {
        if(error.response != undefined){
            return { ok: false, message: error.response.data }
        }else{
            return { ok: false, message: error.message }
        } 
    }

}

export const createHistorical = async ({ commit }, historical) => {

    try {
        
        const {data} = await compoboxApi.post('/m-historical/', historical, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })
        
        commit('addHistorical', {data} )

        return { ok: true }

    } catch (error) {
        if(error.response != undefined){
            return { ok: false, message: error.response.data }
        }else{
            return { ok: false, message: error.message }
        } 
    }

}

