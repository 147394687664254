export const admin = (state) => {
    return state.admin || null
}

export const managers = (state) => {
    if (state.admin?.managers.length > 0) {
        let managers = state.admin.managers.map(manager => {
            return manager
        })
        return managers.sort((a, b) => a.user_data.last_name.localeCompare(b.user_data.last_name))
    }
    else {
        return []
    }
}

export const new_users = (state) => {
    return state.admin.new_users
}

export const admin_users = (state) => {
    return state.admin.admin_users
}

export const sheets_historical = (state) => {
    return state.admin.sheets_historical
}

export const town_halls = (state) => {
    if (state.admin?.managers.length > 0) {
        let town_halls = []
        state.admin.managers.forEach(manager => {
            if (manager.town_halls.length > 0) {
                town_halls = town_halls.concat(manager.town_halls)
            }
        })
        return town_halls.sort((a, b) => a.city.localeCompare(b.city))
    }
    else {
        return []
    }
}

export const machines = (state) => {
    if (state.admin?.managers.length > 0) {
        let machines = []
        state.admin.managers.forEach(manager => {
            if (manager.town_halls.length > 0) {
                manager.town_halls.forEach(th => {
                    if (th.machines.length > 0) {
                        machines = machines.concat(th.machines)
                    }
                })
            }
        })
        return machines.sort((a, b) => a.id - b.id)
    }
    else {
        return []
    }
}

export const neighbors = (state) => {
    if (state.admin?.managers.length > 0) {
        let neighbors = []
        state.admin.managers.forEach(manager => {
            if (manager.town_halls.length > 0) {
                manager.town_halls.forEach(th => {
                    if (th.neighbors.length > 0) {
                        neighbors = neighbors.concat(th.neighbors)
                    }
                })
            }
        })
        return neighbors.sort((a, b) => a.user_data.last_name.localeCompare(b.user_data.last_name))
    }
    else {
        return []
    }
}

export const technicians = (state) => {
    if (state.admin?.managers.length > 0) {
        let technicians = []
        state.admin.managers.forEach(manager => {
            if (manager.technicians.length > 0) {
                technicians = technicians.concat(manager.technicians)
            }
        })
        return technicians.sort((a, b) => a.user_data.last_name.localeCompare(b.user_data.last_name))
    }
    else {
        return []
    }
}

export const farmers = (state) => {
    if (state.admin?.managers.length > 0) {
        let farmers = []
        state.admin.managers.forEach(manager => {
            if (manager.farmers.length > 0) {
                farmers = farmers.concat(manager.farmers)
            }
        })
        return farmers.sort((a, b) => a.user_data.last_name.localeCompare(b.user_data.last_name))
    }
    else {
        return []
    }
}

export const logoInte = (state) => {
    return state.logoInte
}


export const logoCompo = (state) => {
    return state.logoCompo
}

export const pdfSheet = (state) => {
    return state.pdfVisita
}

export const pdfNeighTicket = (state) => {
    return state.pdfNeighTi
}

export const uses = (state) => {
    return state.uses
}


