export default {

    name: 'empresa',
    component: () => import(/* webpackChunkName: "vecino" */ '../layouts/EmpresaLayout.vue'),
    children:[
        {
            path: '',
            name: 'empresa-resumen',
            component: () => import(/* webpackChunkName: "ayuntamiento-resumen" */ '../views/ResumenView.vue'),
        },
        /*{
            path: '/ayuntamiento/compobox',
            name: 'compobox',
            component: () => import(/* webpackChunkName: "ayuntamiento-compobox"  '../views/CompoboxView.vue'),
        },
        {
            path: '/ayuntamiento/vecinos',
            name: 'vecinos',
            component: () => import(/* webpackChunkName: "ayuntamiento-vecinos"  '../views/VecinosView.vue'),
        }*/
    ]

}