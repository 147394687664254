import { createApp } from 'vue'
import store from './store'

import App from './App.vue'

import router from './router/router'

import VCalendar from 'v-calendar';

import VueApexCharts from "vue3-apexcharts";



import '@/assets/styles/reset.css';
import '@/assets/styles/fonts.css';
import '@/assets/styles/atoms.css';
import '@/assets/styles/layout.css';
import '@/assets/styles/menu.css';
import '@/assets/styles/errores.css';

import './registerServiceWorker'

createApp(App).use(store).use(router).use(VCalendar, {}).use(VueApexCharts).mount('#app')