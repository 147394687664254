import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import indexedDB from "../indexedDB"

import admin from '../modules/admin/store'
import auth from '../modules/auth/store'
import ayuntamiento from '../modules/ayuntamiento/store'
import empresa from '../modules/empresa/store'
import gestor from '../modules/gestor/store'
import tecnico from '../modules/tecnico/store'
import agricultor from '../modules/agricultor/store'
import vecino from '../modules/vecino/store'

export default createStore({
    modules: {
        admin,
        auth,
        ayuntamiento,
        empresa,
        gestor,
        tecnico,
        vecino,
        agricultor,
    },
    plugins: [createPersistedState({
        storage: {
            getItem: key => indexedDB.get(key), // Utiliza IndexedDB para obtener los datos
            setItem: (key, value) => indexedDB.set(key, value), // Utiliza IndexedDB para almacenar los datos
            removeItem: key => indexedDB.delete(key), // Utiliza IndexedDB para eliminar los datos
        },
    }),]
})