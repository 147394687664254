export default {

    name: 'tecnico',
    component: () =>
        import( /* webpackChunkName: "vecino" */ '../layouts/TecnicoLayout.vue'),
    children: [{
        path: '',
        name: 'tecnico-resumen',
        component: () =>
            import( /* webpackChunkName: "tecnico-resumen" */ '../views/ResumenView.vue'),
    },
    {
        path: '',
        name: 'tecnico-visitas',
        component: () =>
            import( /* webpackChunkName: "tecnico-visitas" */ '../views/VisitasView.vue'),
    },
    {
        path: '/tecnico/tutoriales',
        name: 'tecnico-tutoriales',
        component: () =>
            import( /* webpackChunkName: "ayuntamiento-vecinos" */ '@/modules/shared/pages/TutorialesView.vue'),
    },
    {
        path: '/tecnico/mensajes',
        name: 'tecnico-mensajes',
        component: () => import(/* webpackChunkName: "ayuntamiento-vecinos" */ '@/modules/shared/pages/MensajesView.vue'),
    },
    {
        path: '/tecnico/datos',
        name: 'tecnico-datos',
        component: () => import(/* webpackChunkName: "ayuntamiento-vecinos" */ '@/modules/shared/pages/DatosView.vue'),
    },
    ]

}