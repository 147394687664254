

export default () => ({
    status: 'authenticating', // 'authenticated','not-authenticated', 'authenticating'
    user: null,
    accessToken: null,
    refreshToken: null,
    sessionExpired: false,
    tutorials: null,
    ranking: {
        local: {
            thisMonth: {},
            lastMonth: {},
            thisYear: {},
        },
        national: {
            thisMonth: {},
            lastMonth: {},
            thisYear: {},
        },
        townhalls: {
            thisMonth: {},
            lastMonth: {},
            thisYear: {},
        }
    },
    rankingFiltered: null,
    neighborRanking: {
        local: {},
        national: {},
        townhalls: {},
    },
    levelRank: null,
    rankingSingleTownHall: null,
    townHallsList: null,
    neighborPoints: null,
})