
export const setGestor = (state, { gestor }) => {

    if (gestor) {
        state.gestor = gestor
    }
}

export const logout = (state) => {
    state.gestor = null
}

export const deleteNeighbor = (state, id) => {

    state.gestor.town_halls.forEach(th => {
        th.neighbors.forEach(n => {
            if (n.user_data.id == id) {
                th.neighbors.splice(th.neighbors.findIndex(item => item.user === id), 1)
            }
        })
    })
}

export const deleteTownHall = (state, id) => {

    state.gestor.town_halls.forEach(th => {
        if (th.user_data.id == id) {
            state.gestor.town_halls.splice(state.gestor.town_halls.findIndex(item => item.user === id), 1)
        }
    })
}

export const deleteTechnician = (state, id) => {

    state.gestor.technicians.forEach(t => {
        if (t.user_data.id == id) {
            state.gestor.technicians.splice(state.gestor.technicians.findIndex(item => item.user === id), 1)
        }
    })
}

export const updateTechnician = (state, { data }) => {

    const ind = state.gestor.technicians.indexOf(state.gestor.technicians.find(item => item.id === data.id))
    state.gestor.technicians[ind] = data

}

export const updateFarmer = (state, { data }) => {

    const ind = state.gestor.farmers.indexOf(state.gestor.farmers.find(item => item.id === data.id))
    state.gestor.farmers[ind] = data

}