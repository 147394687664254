export default {

    name: 'gestor',
    component: () =>
        import ( /* webpackChunkName: "vecino" */ '../layouts/GestorLayout.vue'),
    children: [
        {
            path: '/gestor/informes',
            name: 'gestor-informes',
            component: () =>
                import ( /* webpackChunkName: "ayuntamiento-compobox" */ '../views/InformesView.vue'),
        },
        {
            path: '',
            name: 'gestor-municipios',
            component: () =>
                import ( /* webpackChunkName: "ayuntamiento-vecinos" */ '../views/MunicipiosView.vue'),
        },
        {
            path: '/gestor/suscripcion',
            name: 'gestor-suscripcion',
            component: () =>
                import ( /* webpackChunkName: "ayuntamiento-vecinos" */ '../views/SuscripcionView.vue'),
        },
        {
            path: '/gestor/tutoriales',
            name: 'gestor-tutoriales',
            component: () =>
                import ( /* webpackChunkName: "ayuntamiento-vecinos" */ '@/modules/shared/pages/TutorialesView.vue'),
        },
        {
            path: '/gestor/tecnicos',
            name: 'gestor-tecnicos',
            component: () =>
                import ( /* webpackChunkName: "ayuntamiento-vecinos" */ '../views/TecnicosView.vue'),
        },
        {
            path: '/gestor/mensajes',
            name: 'gestor-mensajes',
            props: true,
            component: () =>
                import ( /* webpackChunkName: "ayuntamiento-vecinos" */ '@/modules/shared/pages/MensajesView.vue'),
        },
        {
            path: '/gestor/datos',
            name: 'gestor-datos',
            component: () =>
                import ( /* webpackChunkName: "ayuntamiento-vecinos" */ '@/modules/shared/pages/DatosView.vue'),
        },
        {
            path: '/gestor/ranking',
            name: 'gestor-ranking',
            component: () =>
                import ( /* webpackChunkName: "ayuntamiento-vecinos" */ '@/modules/shared/pages/RankingView.vue'),
        },
        {
            path: '/gestor/agricultores',
            name: 'gestor-agricultores',
            component: () =>
                import ( /* webpackChunkName: "ayuntamiento-vecinos" */ '../views/AgricultorView.vue'),
        },
    ]

}