
export const setVecino = ( state, { vecino }) => {

    if ( vecino ) {
        state.vecino = vecino
    }
}

export const logout = (state) => {   
    state.vecino = null
}

export const setQuestion = ( state, { data }) => {

    if ( data ) {
        state.question = data
    }
}