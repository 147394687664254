import { createRouter, createWebHashHistory } from 'vue-router'

import isAuthenticatedGuard from '../modules/auth/router/auth-guard'

import adminRouter from '../modules/admin/router'
import ayuntamientoRouter from '../modules/ayuntamiento/router'
import authRouter from '../modules/auth/router'
import vecinoRouter from '../modules/vecino/router'
import gestorRouter from '../modules/gestor/router'
import tecnicoRouter from '../modules/tecnico/router'
import empresaRouter from '../modules/empresa/router'
import agricultorRouter from '../modules/agricultor/router'

const routes = [
    /*   {
        path: '/',
        name: 'Home',
        component: Home
      },
      {
        path: '/about',
        name: 'About',
        component: () => import(/* webpackChunkName: "about" */
    /* '../views/About.vue')
     } 
     */
    {
        path: '/',
        ...authRouter
    },
    {
        path: '/admin',
        beforeEnter: [isAuthenticatedGuard],
        ...adminRouter
    },
    {
        path: '/vecino',
        beforeEnter: [isAuthenticatedGuard],
        ...vecinoRouter
    },
    {
        path: '/ayuntamiento',
        beforeEnter: [isAuthenticatedGuard],
        ...ayuntamientoRouter
    },
    {
        path: '/gestor',
        beforeEnter: [isAuthenticatedGuard],
        ...gestorRouter
    },
    {
        path: '/tecnico',
        beforeEnter: [isAuthenticatedGuard],
        ...tecnicoRouter
    },
    {
        path: '/empresa',
        beforeEnter: [isAuthenticatedGuard],
        ...empresaRouter
    },
    {
        path: '/agricultor',
        beforeEnter: [isAuthenticatedGuard],
        ...agricultorRouter
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'not-found',
        component: () =>
            import ( /* webpackChunkName: "about" */ '@/modules/shared/pages/paginasError/Error404.vue')
    },
    {
        path: '/password_reset',
        name: 'password-reset',
        component: () =>
            import ( /* webpackChunkName: "about" */ '@/modules/shared/pages/ResetPassView.vue')
    },
    {
        path: '/active/:token',
        name: 'active-account',
        component: () => import(/* webpackChunkName: "active-account" */ '@/modules/shared/pages/ActiveAccView.vue'),
        props: ( route ) => {
            return {
                token: route.params.token
            }
        }
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router