import { openDB, deleteDB } from 'idb';

const dbName = 'database';
const storeName = 'vuex';

const dbPromise = openDB(dbName, 1, {
  upgrade(db) {
    db.createObjectStore(storeName);
  },
});

export default {
  async get(key) {
    const db = await dbPromise;
    return db.get(storeName, key);
  },

  async set(key, value) {
    const db = await dbPromise;
    return db.put(storeName, value, key);
  },

  async delete(key) {
    const db = await dbPromise;
    return db.delete(storeName, key);
  },

  async clear() {
    const db = await dbPromise;
    return db.clear(storeName);
  },
};