
export const setTecnico = ( state, { tecnico }) => {

    if ( tecnico ) {
        state.tecnico = tecnico
    }
}

export const updateHistorical = ( state, { data }) => {
    
    const ind = state.tecnico.machines_historical.indexOf(state.tecnico.machines_historical.find(item => item.id === data.id))
    state.tecnico.machines_historical[ind] = data

}

export const addHistorical = ( state, { data }) => {
    
    state.tecnico.machines_historical.push(data)
    state.tecnico.machines_historical.sort((a, b) => {
        return new Date(b.date) - new Date(a.date);
    });

}

export const logout = (state) => {   
    state.tecnico = null
}
