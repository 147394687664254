export default {

    name: 'vecino',
    component: () => import(/* webpackChunkName: "vecino" */ '@/modules/vecino/layouts/LayoutVecino.vue'),
    children: [
        {
            path: '',
            name: 'vecino-resumen',
            component: () => import(/* webpackChunkName: "vecino-resumen" */ '../views/ResumenView.vue'),
        },
        {
            path: '/vecino/monedero',
            name: 'vecino-monedero',
            component: () => import(/* webpackChunkName: "vecino-monedero" */ '../views/MonederoView.vue'),
        },
        {
            path: '/vecino/ranking',
            name: 'vecino-ranking',
            component: () => import(/* webpackChunkName: "vecino-ranking" */ '../views/RankingView.vue'),
        },
        {
            path: '/vecino/tutoriales',
            name: 'vecino-tutoriales',
            component: () =>
                import( /* webpackChunkName: "ayuntamiento-vecinos" */ '@/modules/shared/pages/TutorialesView.vue'),
        },
        {
            path: '/vecino/mensajes',
            name: 'vecino-mensajes',
            component: () => import(/* webpackChunkName: "ayuntamiento-vecinos" */ '@/modules/shared/pages/MensajesView.vue'),
        },
        {
            path: '/vecino/datos',
            name: 'vecino-datos',
            props: true,
            component: () => import(/* webpackChunkName: "ayuntamiento-vecinos" */ '@/modules/shared/pages/DatosView.vue'),
        },
        {
            path: '/vecino/ranking',
            name: 'vecino-ranking',
            component: () => import(/* webpackChunkName: "ayuntamiento-vecinos" */ '@/modules/shared/pages/RankingView.vue'),
        },

    ]

}