export const setAyuntamiento = (state, { ayuntamiento }) => {

    if (ayuntamiento) {
        state.ayuntamiento = ayuntamiento
    }
}

export const logout = (state) => {
    state.ayuntamiento = null
}

export const addNeighbor = (state, { data }) => {

    state.ayuntamiento.neighbors.push(data)
    state.ayuntamiento.neighbors.sort((a, b) => {
        return b.user_data.last_name - a.user_data.last_name;
    });

}

export const updateNeighbor = (state, { data }) => {

    const ind = state.ayuntamiento.neighbors.indexOf(state.ayuntamiento.neighbors.find(item => item.id === data.id))


    if (ind !== -1) {
        const hist = state.ayuntamiento.neighbors[ind].historical
        state.ayuntamiento.neighbors[ind] = data
        state.ayuntamiento.neighbors[ind].historical = hist
    }

}

export const deleteNeighbor = (state, id) => {

        state.ayuntamiento.neighbors.forEach(n => {
            if (n.user_data.id == id) {
                state.ayuntamiento.neighbors.splice(state.ayuntamiento.neighbors.findIndex(item => item.user === id), 1)
            }
        })
}

export const deleteMachine = (state, id) => {

    state.ayuntamiento.machines.forEach(n => {
        if (n.id == id) {
            state.ayuntamiento.machines.splice(state.ayuntamiento.machines.findIndex(item => item.id === id), 1)
        }
    })
}

export const updateAliasMachine = (state, { data }) => {

    const ind = state.ayuntamiento.machines.indexOf(state.ayuntamiento.machines.find(item => item.id === data.id))

    if (ind !== -1) {
        state.ayuntamiento.machines[ind] = data
    }

}

