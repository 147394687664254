export default {

    name: 'admin',
    component: () =>
        import ( /* webpackChunkName: "admin" */ '../layouts/AdminLayout.vue'),
    children: [

        {
            path: '/admin/agricultores',
            name: 'admin-agricultores',
            component: () =>
                import ( /* webpackChunkName: "admin-agricultores" */ '../views/AgricultoresView.vue'),
        },
        {
            path: '/admin/ayuntamientos',
            name: 'admin-ayuntamientos',
            component: () =>
                import ( /* webpackChunkName: "admin-ayuntamientos" */ '../views/AyuntamientosView.vue'),
        },
        {
            path: '',
            name: 'admin-compobox',
            component: () =>
                import ( /* webpackChunkName: "admin-compobox" */ '../views/CompoboxView.vue'),
        },
        {
            path: '/admin/empresas',
            name: 'admin-empresas',
            component: () =>
                import ( /* webpackChunkName: "admin-empresas" */ '../views/EmpresasView.vue'),
        },
        {
            path: '/admin/gestores',
            name: 'admin-gestores',
            component: () =>
                import ( /* webpackChunkName: "admin-gestores" */ '../views/GestoresView.vue'),
        },
        {
            path: '/admin/monederos',
            name: 'admin-monederos',
            component: () =>
                import ( /* webpackChunkName: "admin-monederos" */ '../views/MonederosView.vue'),
        },
        {
            path: '/admin/ranking',
            name: 'admin-ranking',
            component: () =>
                import ( /* webpackChunkName: "ayuntamiento-vecinos" */ '@/modules/shared/pages/RankingView.vue'),
        },
        {
            path: '/admin/tecnicos',
            name: 'admin-tecnicos',
            component: () =>
                import ( /* webpackChunkName: "admin-tecnicos" */ '../views/TecnicosView.vue'),
        },
        {
            path: '/admin/tutoriales',
            name: 'admin-tutoriales',
            component: () =>
                import ( /* webpackChunkName: "admin-tutoriales" */ '../views/TutorialesView.vue'),
        },
        {
            path: '/admin/vecinos',
            name: 'admin-vecinos',
            component: () =>
                import ( /* webpackChunkName: "admin-vecinos" */ '../views/VecinosView.vue'),
        },
        {
            path: '/admin/mensajes',
            name: 'admin-mensajes',
            component: () =>
                import ( /* webpackChunkName: "admin-mensajes" */ '@/modules/shared/pages/MensajesView.vue'),
        },
        {
            path: '/admin/datos',
            name: 'admin-datos',
            component: () =>
                import ( /* webpackChunkName: "admin-datos" */ '@/modules/shared/pages/DatosView.vue'),
        },
    ]

}